import * as React from 'react';

import { Footer, NavMenu, PageTitle } from '../components/shared';

import { Col, Container, Row, Stack } from 'react-bootstrap';
import { faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QuestionAnswer } from '../components/shared/QuestionAnswer';

const FaqPage = () => {
  return (
    <>
      <PageTitle />
      <NavMenu solidColor />
      <Container className="full-page">
        <Stack className="mt-4">
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 24, justifyContent: 'center' }}>
            <FontAwesomeIcon
              icon={faQuestionCircle}
              size="7x"
              fixedWidth
              color="#fff2e9"
              style={{ '--fa-primary-color': '#fc6a01', '--fa-secondary-opacity': '1' }}
            />
            <h1>Frequently Asked Questions</h1>
          </div>

          <div className="bg-light-blue mb-4" style={{ padding: 16, borderRadius: 25 }}>
            <h2 className="text-center mb-3">
              Most Commonly <br className="d-block d-sm-none" /> Asked Questions
            </h2>
            <Row>
              <Col md={6}>
                <QuestionAnswer title="Why should I use a tax consultant to appeal my property taxes?">
                  Just as you would hire a CPA for income tax preparation, employing a property tax consultant to protest your property taxes is equally
                  essential. Our team is well-versed in property valuation techniques, local tax regulations, and appraisal district protocols. We are committed
                  to achieving the best results every year for our clients.
                </QuestionAnswer>
                <QuestionAnswer title="How does TTP stand out from its competitors?">
                  We focus on cultivating strong relationships with our clients to best serve them. We also employ a customized strategy to reduce your property
                  taxes, avoiding the one-size-fits-all approach common among many firms. We want you to win! Unlike others firms that may settle for minimal
                  reductions, our team is committed to securing the maximum possible reduction for you.
                </QuestionAnswer>
                <QuestionAnswer title="Can you provide an estimate of my potential tax savings?">
                  No, under TX law we cannot provide an estimate.
                  <br />
                  <br />
                  We recommend being cautious of property tax protest providers that offer instant savings estimates or provide figures before thoroughly
                  researching your property.
                  <br />
                  <br />
                  In fact, Texas regulatory agency, TDLR does not permit tax consultants to:
                  <br />
                  <i>
                    “to solicit or advertise property tax consulting services by claiming a specific result or stating a conclusion regarding such services
                    without prior analysis of the facts and circumstances pertaining thereto.” <strong>RULE §66.100(m)</strong>{' '}
                    <a
                      href="https://texreg.sos.state.tx.us/public/readtac$ext.TacPage?sl=R&app=9&p_dir=&p_rloc=&p_tloc=&p_ploc=&pg=1&p_tac=&ti=16&pt=4&ch=66&rl=100"
                      target="_blank"
                    >
                      View More
                    </a>
                  </i>
                  <br />
                  <br />
                  Consultants that solicit or advertise property tax consulting services by claiming a specific result are in violation of the law.
                </QuestionAnswer>
                <QuestionAnswer title="What is the cost of your service?">
                  We offer competitive pricing for homeowners, investors, and commercial property owners. Pricing varies based on the number of properties and
                  property valuation. All pricing can be viewed by searching for your property on our online sign-up portal and viewing the details.
                </QuestionAnswer>
                <QuestionAnswer title="In which counties are your services available?">
                  We are proud to provide property tax protest services for both commercial and residential properties across most of the 254 Texas counties.
                  This allows you to partner with a single company for all your Texas properties. Over a decade, our experienced team has built strong
                  connections with local appraisal offices and an in-depth understanding of the local market trends and forces that affect your property tax
                  appraisals. This knowledge equips our tax consultants to deliver compelling arguments to help reduce your taxes.
                </QuestionAnswer>
              </Col>
              <Col md={6}>
                <QuestionAnswer title="How have you performed for your clients?">
                  In our view, each property is unique and deserves a thorough analysis. Our strategy involves diligently reviewing relevant details about your
                  property to formulate a solid case. This process unfolds over the duration of the appeal process.
                  <br />
                  <br />
                  We highlight some of our recent success stories on our <a href="/case-studies">case studies</a> page.
                </QuestionAnswer>
                <QuestionAnswer title="What are the chances of successfully lowering my appraised value if I enroll?">
                  During the 2023 property tax protest season, we secured appraisal reductions for over 80% of our clients. Although we can't promise specific
                  outcomes, we can provide average statistics from our previous work. For more detailed statistics, please explore our residential and
                  commercial <a href="/case-studies">case studies</a>.
                </QuestionAnswer>
                <QuestionAnswer title="Is it risky to appeal? Can my assessed value be raised if I protest?">
                  The short answer is no. At an informal or formal hearing, the central appraisal district is not permitted to raise your assessed value so
                  there is no risk to you.
                </QuestionAnswer>
                <QuestionAnswer title="How do I enroll?">
                  Signing up with us is quick and usually takes just a few minutes. Use the “Sign Up Now” button on our <a href="/">home page</a>.
                </QuestionAnswer>
              </Col>
            </Row>
          </div>
          <div className="bg-light-orange mb-4" style={{ padding: 16, borderRadius: 25 }}>
            <h2 className="text-center mb-3">Property Value</h2>
            <Row>
              <Col md={6}>
                <QuestionAnswer title="How does the appraisal district establish the value of my property?">
                  The appraisal district employs a mass appraisal system to value your property, relying on a large database that may include inaccurate or
                  outdated information about your property. That means each year, there is usually no one from the district conducting a personal review of your
                  individual property. The mass appraisal method often introduces errors, providing us with significant opportunities to reduce your property
                  taxes.
                </QuestionAnswer>
                <QuestionAnswer title={`What is the difference between "market value" and "assessed value"?`}>
                  Market value is often higher than assessed value because market value reflects the current conditions and prices in the real estate market,
                  whereas assessed value is determined by the local tax assessor for tax purposes and may include caps or exemptions that limit increases in
                  taxable value.
                </QuestionAnswer>
                <QuestionAnswer title="If I protest my property taxes, does that mean my house is worth less?">
                  No, no, no. The market value of a home is determined by recent sale in an area, not by the appraisal district’s value. If you plan to sell
                  your house, it may actually be attractive to a buyer if the taxes on your home are less. That means less out of pocket for them.
                  <br />
                  <br />
                  <em>
                    Texas Tax Protest is available year round to answer your property tax questions and address any concerns you may have about the protest
                    process. When you call you will always have the opportunity to speak with one of our agents. No recordings, just real people.
                  </em>
                </QuestionAnswer>
                <QuestionAnswer title="If my property is assessed for less than market value, can I still receive a reduction in my assessed value?">
                  Yes you can by using the unequal appraisal method. The appraisal districts permit you to compare the assessed value of your property to the
                  assessed value of similar properties. If the similar properties are also assessed below market value and your property is appraised higher
                  than the others, you may be eligible for a reduction.
                  <br />
                  <br />
                  <em>Texas Tax Protest uses the unequal appraisal method in many cases to reduce homeowner’s property taxes!</em>
                </QuestionAnswer>
              </Col>
              <Col md={6}>
                <QuestionAnswer title="If my property is already valued lower than its potential sale price, why should I consider protesting?">
                  Even when a property is valued well below its potential sale price, we frequently uncover opportunities to reduce property taxes. This success
                  often stems from employing various protest strategies under the equal and uniform tax law, such as identifying discrepancies in how your
                  property is taxed compared to others in the area.
                </QuestionAnswer>
                <QuestionAnswer title={`Why is my property valued higher than my neighbor's?`}>
                  If your property is appraised higher than similar neighboring properties, it may be due to specific property characteristics in the appraisal
                  district's database. After we file an appeal, we gain access to detailed evidence outlining the appraisal district’s calculations and
                  methodology. This evidence package often contains inaccuracies or outdated information. We meticulously review the appraisal district's report
                  and address any discrepancies to help reduce your property tax bill. Additionally, even if your property is valued lower than your neighbors,
                  there are other appeal strategies that can result in tax savings.
                </QuestionAnswer>
                <QuestionAnswer title="Will the appraisal district inspect my house if I protest?">
                  No. Our goal with the appeal process is not to put any undue time burden or inconvenience on our clients. We have never had a county appraiser
                  go inside anybody’s home. However, if there are some condition items (like an outdated kitchen or you are in need of a new roof), typically we
                  would work with our clients to provide photos or contractor estimates in lieu of an onsite visit. However, the majority of the protest we
                  settle are normal homes in good working order and we rely on comparable properties to reduce the assessed value, not on pictures.
                </QuestionAnswer>
              </Col>
            </Row>
          </div>
          <div className="bg-light-blue mb-4" style={{ padding: 16, borderRadius: 25 }}>
            <h2 className="text-center mb-3">Property Tax Protest</h2>
            <Row>
              <Col md={6}>
                <QuestionAnswer title="Appealing my property taxes seems overwhelming. Where do I start?">
                  Each appraisal district has their own process and guidelines to help homeowners protest.
                  <br />
                  <br />
                  <em>
                    Texas Tax Protest takes all the guess work and uncertainty out of the protest process. Start by signing up with us. The process only
                    requires three forms, which we fill out for you. All you need to do is review and sign. It’s really that easy!
                  </em>
                </QuestionAnswer>
                <QuestionAnswer title="When is the property tax appeal filing deadline?">
                  The deadline to file a protest is May 15th, or 30 days after the Notice of Appraised Value is sent, whichever comes later. If May 15th is on a
                  weekend or holiday, the deadline extends to the next business day. It’s advisable to sign up with us early to allow ample time to prepare and
                  file your protest.
                </QuestionAnswer>
                <QuestionAnswer title="I missed the May 15th tax protest deadline; is it still possible to retain your services?">
                  If you've missed your protest deadline, you may still be eligible to file a 25.25 late protest depending on specific circumstances. It’s
                  crucial that you understand what to expect from a late protest filing. Please reach out to us for further details.
                  <br />
                  <br />
                  Additionally, if your notice of appraised value is dated after April 15th, you will have additional time to file deadline beyond May 15th
                  deadline. We may still be able to assist you for the current tax year on a case-by-case basis.
                  <br />
                  <br />
                  The appeal deadline is May 15th or 30 days after notice of your assessed value is mailed to you, whichever is later
                  <br />
                  <br />
                  No, we allow clients to provide us photos and documents through their annual property survey. The annual questionnaire helps us understand the
                  condition of your property. While, we provide clients the most value thorough data research, due diligence, and utilizing our proprietary
                  technology. Coupling our evidence with client provided evidence often presents a compelling case for a appraisal reduction.
                </QuestionAnswer>
                <QuestionAnswer title="What can I do if I missed the appeal deadline?">
                  There are only a few situations in which a property owners can file a late protest,
                  <br />
                  <br />
                  First, non-homesteads, you can file a late protest if there is a significant overvaluation, known as a 25.25(d) protest. To qualify, you must
                  demonstrate that your property is appraised at least one-third higher than its true value. For instance, if your property is appraised at
                  $400,000, you must show that its actual value is less than $300,000 to even be considered for a reduction. This protest must be filed by
                  December 31st of the current tax year, your taxes must be paid on time, and you must not have filed another protest for the same year.
                  <br />
                  <br />A property owner can also make a case under a 25.25(d) for a residential homesteads if the property is over-appraised by at least
                  one-fourth.
                  <br />
                  <br /> Additionally, you can seek to reopen the appraisal roll for up to four past tax years to correct clerical errors, such as
                  miscalculations or exemption issues, through a 25.25c protest. However, a 25.25c cannot be used to contest the appraised value itself.
                </QuestionAnswer>
                <QuestionAnswer title="I filed my own protest.  Can I still hire you?">
                  If you have already filed your protest, we cannot take you on as a new client for the current tax year. We achieve the best reduction results
                  when we manage the entire protest process from start to finish. Please consider reaching out to us next year!
                </QuestionAnswer>
                <QuestionAnswer title="How can I submit documentation regarding the condition of my property?">
                  Once you engage with us, we will send you a link to a property questionnaire. You may provide any documents that could support a case for
                  lowering your property's valuation.
                </QuestionAnswer>
              </Col>
              <Col md={6}>
                <QuestionAnswer title="Does providing the sale price of my recently acquired property offer any assistance?">
                  Yes. If you acquired the property for a higher amount than its appraisal value, it's crucial for us to be informed of the sales price. We can
                  still appeal your valuation under other statues of the property tax code. Please note, we treat your purchase documents with utmost care,
                  ensuring confidentiality. We will only disclose the sales price, if it contributes to reducing your property taxes and if no other strategies
                  for achieving a stronger reduction are available.
                </QuestionAnswer>
                <QuestionAnswer title="Can I attend meetings with the Appraisal District and Appraisal Review Board?">
                  Our licensed property tax consultants, having attended thousands of hearings, are skilled at securing the best outcomes for our clients.
                  Occasionally, clients may unintentionally present evidence that undermines our case. To ensure the most effective representation, it is
                  preferable for our consultants to engage directly with the appraisal district.
                </QuestionAnswer>
                <QuestionAnswer title="When do I get my appeal results?">
                  Our hearings typically conclude during the late summer and fall months. However, the timing is largely determined by the appraisal district,
                  as they assign our hearing dates.
                </QuestionAnswer>
                <QuestionAnswer title="What if I don’t like my appeal results?">
                  Appeal results are typically final, with some additional taxpayer remedies available for escalation.
                  <br />
                  <br />
                  Our experts strive to secure the optimal outcome for you. Though infrequent, there are instances where the appraisal district presents
                  compelling evidence that is challenging to counter. To discuss your case further and review escalation options, please don't hesitate to
                  contact our team.
                </QuestionAnswer>
                <QuestionAnswer title="When do the new tax rates come out?">
                  Tax rates are typically set in November of the current tax year.
                  <br />
                  <br />
                  <em>
                    Texas Tax Protest has no control over tax rates, but we can offer our expertise in helping you reduce you home’s assessed value so you pay
                    less in property taxes overall.
                  </em>
                </QuestionAnswer>
                <QuestionAnswer title="Do you file a protest every year?">
                  Yes, we will file a protest annually for our client’s properties. There are no drawbacks to filing an appeal. Our process involves filing the
                  protest, conducting all necessary research, requesting evidence from the county, and initially negotiating informally with the appraisal
                  district. If it becomes apparent that your case is weak or a reduction is not feasible after these informal attempts, we reserve the right to
                  withdraw the protest before the ARB formal hearing or to settle for no change in value. In some cases, we may also maintain your rights to
                  arbitration and judicial appeals in no-change situations.
                  <br />
                  <br />
                  As we file protests for every property, our appraisal reduction success rate includes properties in no-change scenarios, reflecting that 80%+
                  of our clients achieve a reduction.
                </QuestionAnswer>
              </Col>
            </Row>
          </div>
        </Stack>
      </Container>
      <Footer />
    </>
  );
};

export default FaqPage;
