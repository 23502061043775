import * as React from 'react';
import { Card, Collapse } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

interface QuestionAnswerProps {
  title: string;
  children: React.ReactNode;
}

export const QuestionAnswer: React.FC<QuestionAnswerProps> = (props: QuestionAnswerProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Card className={`faq-item ${open ? 'open' : ''}`}>
        <Card.Header>
          <button onClick={() => setOpen(!open)} type="button" style={{ backgroundColor: 'transparent', border: 'none' }}>
            {open ? <FontAwesomeIcon icon={faTimesCircle} fixedWidth /> : <FontAwesomeIcon icon={faPlusCircle} fixedWidth />}
            {props.title}
          </button>
        </Card.Header>
        <Collapse in={open}>
          <div>
            <Card.Body>{props.children}</Card.Body>
          </div>
        </Collapse>
      </Card>
    </>
  );
};
